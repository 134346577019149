import {
  ProvisioningSourceFilter,
  ProvisioningStatusFilter,
} from 'bulkProvisioning/BulkProvisioning.interface';

type Filters = {
  fileName: string;
  orderNumber: string;
  uploadDateFrom: Date | null;
  uploadDateTo: Date | null;
  status: ProvisioningStatusFilter;
  source: ProvisioningSourceFilter;
};

export const provisioningQueries = {
  all: ['provisioning'] as const,
  inventory: (filters: Filters) => {
    return [
      ...provisioningQueries.all,
      filters.fileName,
      filters.orderNumber,
      filters.uploadDateFrom ? filters.uploadDateFrom.toISOString() : '',
      filters.uploadDateTo ? filters.uploadDateTo.toISOString() : '',
      filters.status,
    ];
  },
  downloadTemplate: ['provisioning', 'downloadTemplate'] as const,
};
