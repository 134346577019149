import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { MutationKey, MutationFunction, useMutation } from '@tanstack/react-query';
import { AuthError } from 'auth/utils';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  MaxSimProfilesCountError,
  ReportAlreadyScheduledError,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import { ExportError, ExportCardsSnackbars } from './ExportCardsSnackbars';
import { LargeExportPrompt } from './LargeExportPrompt';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

type ExportReportButtonProps = {
  checkIsLargeReport: () => Promise<boolean>;
  disabled?: boolean;
  exportMutation: {
    mutationKey: MutationKey;
    mutationFn: MutationFunction<unknown>;
  };
  numberOfEntries: number;
};

export const ExportReportButton = ({
  exportMutation,
  checkIsLargeReport,
  numberOfEntries,
  disabled,
}: ExportReportButtonProps) => {
  const { t } = useTranslation();
  const [exportError, setExportError] = useState<ExportError | null>(null);
  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);
  const [confirmationRequire, setConfirmationRequire] = useState(false);
  const [largeExport, setLargeExport] = useState(false);

  const { mutate, isPending } = useMutation<unknown>({
    ...exportMutation,
    retry: 0,
    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      }

      if (error instanceof MaxSimProfilesCountError) {
        setExportError({
          title: t('common.exportFailed'),
          description: [
            t('simInventory.maxSimProfilesCountError', {
              maxSimProfiles: error.maxSimProfilesCount,
            }),
          ],
        });
      } else if (error instanceof ReportAlreadyScheduledError) {
        setExportError({
          title: t('common.exportFailed'),
          description: [
            t('simInventory.anotherBackgroundExportIsStillInProgress'),
            t('simInventory.pleaseTryAgainLater'),
          ],
        });
      } else {
        setExportError({
          title: t('common.requestFailed'),
          description: [t('common.somethingWrong')],
        });
      }
    },
    onSuccess: () => {
      setDownloadingSnackbarOpened(true);
    },
  });

  const exportReport = async () => {
    const largeExport = await checkIsLargeReport();

    setLargeExport(largeExport);
    setConfirmationRequire(largeExport);
    if (!largeExport) {
      mutate();
    }
  };

  return (
    <Stack>
      <Button
        startIcon={<FileDownloadIcon />}
        disabled={disabled || isPending}
        color="secondary"
        onClick={exportReport}
      >
        {t('common.export')}
      </Button>
      <LargeExportPrompt
        open={confirmationRequire}
        onConfirm={async () => {
          mutate();
          setConfirmationRequire(false);
        }}
        onClose={() => {
          setConfirmationRequire(false);
          setLargeExport(false);
        }}
        title={t('simInventory.simInventoryExport')}
        message={
          <>
            <Trans
              i18nKey={'simInventory.requestOfNSimsExport'}
              values={{ numberOfCards: numberOfEntries }}
              components={{ 1: <strong /> }}
            />
            <div>{t('common.emailWithDownloadLink')}</div>
          </>
        }
      />
      <ExportCardsSnackbars
        opened={downloadingSnackbarOpened}
        largeExportSuccessTitle={t('simInventory.exportingNSims', {
          numberOfCards: numberOfEntries,
        })}
        largeExport={largeExport}
        setExportError={setExportError}
        setDownloadingSnackbarOpened={setDownloadingSnackbarOpened}
        setLargeExport={setLargeExport}
        exportError={exportError}
      />
    </Stack>
  );
};
