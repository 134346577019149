import SimCardIcon from '@mui/icons-material/SimCard';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  downloadBulkProvisioningInputFile,
  downloadErrorSummary,
  fetchBulkProvisioningJobs,
} from '../BulkProvisioningApi/bulkProvisioningApi';
import { useDebounce } from 'common/useDebounce';
import { usePaginatedQuery } from 'common/usePaginatedQuery';
import { Routes } from '../../Routes.constants';
import { BulkProvisioningJobs } from '../BulkProvisioningApi/bulkProvisioningApi.interface';
import { ProvisioningFilters, ProvisioningSource } from '../BulkProvisioning.interface';
import { useTranslation } from 'react-i18next';
import { Actions, Subjects } from 'permissions/ability';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import { ConfigurableTable } from 'common/components/ConfigurableTable/ConfigurableTable';
import { Configuration } from 'common/components/ConfigurableTable/hooks/usePrepareConfiguration';
import { StatusBadgesCell } from '../Components/Cells/StatusBadgesCell';
import {
  CounterCell,
  CounterCellFailed,
  CounterCellSuccess,
} from '../Components/Cells/CounterCell';
import { DateTimeCell } from 'common/components/ConfigurableTable/Components/Cells/DateTimeCell';
import { useAbility } from 'permissions/hooks/useAbility';
import { BulkProvisioningNoRowsOverlay } from '../Components/BulkProvisioningNoRowsOverlay';
import { mapSourceValueToLabel } from 'bulkProvisioning/BulkProvisioning.utils';
import { provisioningQueries } from '../utils/queries';

export type BulkProvisioningJobsTableProps = {
  searchProps: ProvisioningFilters & { fileName: string };
};

const TextRenderComponent = ({ value }: any) => {
  return <>{value}</>;
};

export const BulkProvisioningJobsTable: React.FC<BulkProvisioningJobsTableProps> = ({
  searchProps: { status, uploadDateFrom, uploadDateTo, fileName, source, orderNumber },
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const debouncedFileName = useDebounce(fileName, 200);

  const {
    queryResult,
    pagination: { page, setPage, rowsPerPage, setRowsPerPage },
  } = usePaginatedQuery<BulkProvisioningJobs>(
    provisioningQueries.inventory({
      fileName: debouncedFileName,
      orderNumber: orderNumber,
      uploadDateFrom: uploadDateFrom,
      uploadDateTo: uploadDateTo,
      status: status,
      source: source,
    }),
    ({ signal, page, rowsPerPage }) =>
      setMinimalPromiseTime(
        fetchBulkProvisioningJobs(
          page,
          rowsPerPage,
          status,
          source,
          uploadDateFrom,
          uploadDateTo,
          debouncedFileName,
          orderNumber,
          signal,
        ),
      ),
  );

  const { data: bulkProvisioningJobs } = queryResult;

  const { pathname, search } = useLocation();

  const configuration: Configuration[] = [
    {
      field: 'fileName',
      headerName: t('common.fileName'),
      resizable: false,
      sortable: false,
      pinnable: false,
      minWidth: 300,
    },
    {
      field: 'status',
      headerName: t('common.status'),
      resizable: false,
      sortable: false,
      pinnable: false,
      width: 230,
    },
    {
      field: 'numberOfCards',
      headerName: t('common.numberOfCards'),
      resizable: false,
      sortable: false,
      pinnable: false,
    },
    {
      field: 'numberOfSuccessCards',
      headerName: t('common.success'),
      resizable: false,
      sortable: false,
      pinnable: false,
    },
    {
      field: 'numberOfFailedCards',
      headerName: t('common.failed'),
      resizable: false,
      sortable: false,
      pinnable: false,
    },
    {
      field: 'uploadAt',
      headerName: t('common.uploadTime'),
      resizable: false,
      sortable: false,
      pinnable: false,
      width: 180,
    },
    {
      field: 'uploadFinishedAt',
      headerName: t('bulkProvisioning.processingFinishedTime'),
      resizable: false,
      sortable: false,
      pinnable: false,
      width: 180,
    },
    {
      field: 'source',
      headerName: t('common.source'),
      valueGetter: (value: ProvisioningSource) => mapSourceValueToLabel(value, t),
      resizable: false,
      sortable: false,
      pinnable: false,
    },
    {
      field: 'orderNumber',
      headerName: t('common.orderNumber'),
      resizable: false,
      sortable: false,
      pinnable: false,
    },
    {
      field: 'uploadBy',
      headerName: t('simInventory.userName'),
      resizable: false,
      sortable: false,
      pinnable: false,
      flex: 1,
    },
  ];

  const ability = useAbility();

  return (
    <ConfigurableTable
      disableColumnReorder
      actions={[
        {
          label: t('bulkProvisioning.viewDetails'),
          icon: <SimCardIcon />,
          closable: true,
          actionCallback: (data: any) => {
            navigate(`${Routes.provisioningRequest}/${data.id}${search}`, {
              state: { previousPath: `${pathname}${search}` },
            });
          },
        },
        {
          label: t('bulkProvisioning.downloadInput'),
          icon: <DownloadIcon />,
          closable: false,
          enabled: ability.can(Actions.export, Subjects.bulkProvisioning),
          actionCallback: (data: any) => {
            downloadBulkProvisioningInputFile(data.id);
          },
          slotProps: {
            menuItem: {
              'data-testid': 'downloadInputFileButton',
            },
          },
        },
        {
          label: t('bulkProvisioning.downloadFailedResults'),
          icon: <CloseIcon />,
          closable: false,
          enabled: (data: any) => !!data.errorSummaryFileName,
          actionCallback: (data: any) => {
            downloadErrorSummary(data.id);
          },
        },
      ]}
      data-testid="bulk provisioning table"
      configuration={configuration}
      rows={bulkProvisioningJobs?.items || []}
      cellConfiguration={{
        numberOfCards: CounterCell,
        numberOfSuccessCards: CounterCellSuccess,
        numberOfFailedCards: CounterCellFailed,
        status: StatusBadgesCell,
        uploadAt: DateTimeCell,
        uploadFinishedAt: DateTimeCell,
        default: TextRenderComponent,
      }}
      totalNumberOfItems={bulkProvisioningJobs?.totalNumberOfItems ?? 0}
      onPaginationChange={(pagination) => {
        if (pagination.page !== page) {
          setPage(pagination.page);
        }

        if (pagination.pageSize !== rowsPerPage) {
          setRowsPerPage(pagination.pageSize);
        }
      }}
      page={page}
      pageSize={rowsPerPage}
      slots={{
        noRowsOverlay: BulkProvisioningNoRowsOverlay,
      }}
    />
  );
};
